import React from "react"
import * as styles from "./hmm.module.css"
import Container from "../components/container"
import Layout from "../components/layout"
console.log(styles)
//1ca086
const Member = props => (
  <div className={styles.user}>
    <frame src={props.avatar} alt="" />
    <div className={styles.description}>
      <h2 className={styles.username}>{props.name}</h2>
      <p className={styles.excerpt}>{props.excerpt}</p>
    </div>
  </div>
)

export default function About() {
  return (
    <Layout>
      <Container>
        <h1>Members</h1>
        <p>CORE has jokers.</p>
        <Member
          name="Raghav Puri"
          avatar="https://drive.google.com/file/d/1TF5G8HY70hWwtv_jRtl1Ea1c9dHDfYa7/preview"
          excerpt="He a good man. he a nigga doe. so fuck off."
        />
        <Member
          username="KKK White"
          avatar="https://s3.amazonaws.com/uifaces/faces/twitter/vladarbatov/128.jpg"
          excerpt="He a bad white man. he a racist doe. so full sapot."
        />
      </Container>
    </Layout>
  )
}
